<template lang="">
  <Card>
    <template #header> Görüntüleme Grafikleri </template>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="">Sahibinden sitesi görünütlenme sayısı</label>
          <input v-model="sahibinden" type="text" class="form-control" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="">Hürriyet Emlak görüntülenme sayısı</label>
          <input v-model="hurriyet" type="text" class="form-control" />
        </div>
      </div>
    </div>
    <div class="d-flex w-100 justify-content-center align-items-center flex-column">
        <div class="d-flex align-items-center">
            <span class="badge yellow"></span>
            <p class="ml-1">Sahibinden : {{sahibinden}}</p>
        </div>
        <div class="d-flex align-items-center">
            <span class="badge red"></span>
            <p class="ml-1">Hürriyet Emlak : {{hurriyet}}</p>
        </div>
        <div class="d-flex align-items-center">
            <span class="badge primary"></span>
            <p class="ml-1">Bankamdan : {{bankamdan}}</p>
        </div>
        <hr class="bg-dark h-4 w-100" />
        <div class="d-flex align-items-center">
            <span class="badge primary"></span>
            <p class="ml-1">Toplam : {{toplam}}</p>
        </div>
    </div>
    <div class="form-group d-flex">
        <button class="btn btn-success ml-auto">Kaydet</button>
      </div>
  </Card>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  data(){
    return{
      sahibinden:0,
      hurriyet:0,
      bankamdan:0,
      toplam:0,
    }
  },
  created(){
    this.getList()
  },
  methods:{
    getList(){
  
        let query = `advert/viewing?Page=&user_token=${this.myUser}&advert_id=${this.$route.params.id}`;
       
  
        this.$dbFunctions
          .get(query)
          .then((res) => {

              this.sahibinden = res.response.data[0].sahibinden_ofwiew
              this.bankamdan = res.response.data[0].bankamdan_ofview
              this.hurriyet = res.response.data[0].hurriyet_ofwiew
              this.toplam = parseInt( this.sahibinden) + parseInt( this.bankamdan) + parseInt( this.hurriyet) 

            
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });

    }
  },
  computed:{
    ...mapGetters(["myUser"])
  }

};
</script>
<style lang="scss" scoped>

      .badge {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        padding: 0 !important;
  
        &.green {
          background-color: #44b6ae;
        }
        &.yellow {
          background-color: #ffac00;
        }
        &.red {
          background-color: red;
        }
        &.primary{
            background-color: $dveb-primary;
        }
      }
    
</style>
